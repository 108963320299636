import React from "react"
import ReactMarkdown from "react-markdown"

import remarkGfm from "remark-gfm"

import "./Marcdown.css"

export default function Marcdown(props) {
  const { className } = props
  return (
    <div className={className}>
      <ReactMarkdown children={props.config.source} linkTarget="_blank" remarkPlugins={[remarkGfm]} />
    </div>
  )
}