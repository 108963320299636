import React from 'react';
import './ImageGrid.css';

const Imagegrid = ({ images }) => {

    return (
        <div className="image-grid">
            {images.map((image, index) => (
                <div key={index} className="image-item">
                    <img src={`${process.env.PUBLIC_URL}${image}`} alt={`img-${index}`} />
                </div>
            ))}
        </div>
    );
};

export default Imagegrid;