import React, { useEffect, useState } from 'react';
import './App.css';

import {
  Button,
  Typography,
  CssBaseline,
  createTheme,
  ThemeProvider
} from '@mui/material';

import Economica from './fonts/Economica-Regular.ttf';
import Imagegrid from './ImageGrid.js';
import Marcdown from './components/Marcdown/index.js'

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const theme = createTheme({
  palette: {
    frontcolor: createColor('#f6ae22'),
    buttoncolor: createColor('#a0926b')
  },
  typography: {
    fontFamily: 'Economica',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Economica';
          src: local('Economica'), url(${Economica}) format('opentype');
        }
      `,
    },
  },
});

function App() {
  const [selectedYear, setSelectedYear] = useState(2024)
  const [images, setImages] = useState([])
  const [markdown, setMarkdown] = useState("")
  const [hideIntro, setHideIntro] = useState(false)

  // When selectedYear has changed change the rendered markdown file and set the images to the correct array of files
  useEffect(() => {
    const path = `./markdowns/${selectedYear}.md`
    fetch(path).then((response) => {
      response.text().then((text) => {
        setMarkdown(text)
      })
    })

    switch (selectedYear) {
      case 2020:
        setImages(["./images/2020/img_001.png", "./images/2020/img_002.png"])
        break;

      case 2022:
        setImages(["./images/2022/001.jpg", "./images/2022/002.jpg", "./images/2022/003.jpg"])
        break;

      case 2023:
        setImages(["./images/2023/img_001.JPG", "./images/2023/img_002.jpg", "./images/2023/img_003.JPG", "./images/2023/img_004.jpg", "./images/2023/img_005.jpg", "./images/2023/img_006.jpg"])
        break;

      case 2024:
        setImages(["./images/2024/img_001.jpeg", "./images/2024/img_002.jpg", "./images/2024/img_003.jpg", "./images/2024/img_004.jpg", "./images/2024/img_005.jpg", "./images/2024/img_006.jpg"])
        break;
      default:
        setImages([])
        break;
    }
  }, [selectedYear])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="app" style={{ marginLeft: theme.spacing(1), marginRight: theme.spacing(1) }}>
        <div style={{ height: '100%', overflow: "hidden", display: "flex", margin: "0 auto", flexDirection: "column", width: "100%" }}>
          <Typography sx={{ margin: "0 auto" }} variant='h3'>Whisk(e)yAvond</Typography>
          <Button sx={{ marginBottom: theme.spacing(1) }} variant='contained' color="buttoncolor" onClick={(e) => { setHideIntro(!hideIntro) }}>{`Introductie ${hideIntro ? "openen" : "sluiten"}`}</Button>
          <div style={{ display: hideIntro ? "none" : "flex", flexDirection: "column" }}>
            <Typography variant='body1'> Welkom op de officiële pagina van Whisk(e)yAvond, een prestigieus evenement voor de ware connaisseurs van het edele distillaat. Op onze avonden verzamelen wij een selecte groep gelijkgestemden om gezamenlijk de rijke en gevarieerde wereld van whisk(e)y te verkennen en te vieren.</Typography>
            <br></br>
            <Typography >Iedere deelnemer brengt een zorgvuldig gekozen fles mee en krijgt de gelegenheid deze te presenteren aan de overige genodigden. Dit creëert een unieke sfeer van collegiale competitie en gedeelde passie. Elke editie van Whisk(e)yAvond heeft een ander thema, waardoor deelnemers de kans krijgen om hun creativiteit te tonen en nieuwe aspecten van whisk(e)y te ontdekken.</Typography>
            <br></br>
            <Typography >Hoewel wij onszelf als een whisk(e)y proeverij beschouwen, ligt de nadruk op het genieten en de informele gezelligheid. Aan het eind van iedere avond wordt een winnende fles verkozen, een traditie die voortvloeit uit het enthousiasme en de smaakvoorkeuren van onze deelnemers.</Typography>
            <br></br>
            <Typography >Op deze website zult u sfeervolle impressies van voorgaande edities kunnen aanschouwen, zodat u een glimp kunt opvangen van de unieke ambiance die Whisk(e)yAvond zo speciaal maakt.</Typography>
            <br></br>
            <Typography > ~ Marc Jansen, oprichter Whisk(e)yAvond</Typography>
            <img alt="Whisk(e)yAvond Logo" style={{ display: "flex", margin: "0 auto", height: "200px", width: "200px", marginBottom: theme.spacing(1) }} src='./whiskeyavond.png'></img>
          </div>
        </div>
        <div className="scroll-container" style={{ marginTop: theme.spacing(2), display: "flex", flexDirection: "row", flexWrap: "wrap", margin: "0 auto", width: "100%", justifyContent: "space-between", overflow: "scroll" }}>
          <Button sx={{ margin: theme.spacing(0.5) }} variant='contained' color="buttoncolor" onClick={e => setSelectedYear(2019)}>2019</Button>
          <Button sx={{ margin: theme.spacing(0.5) }} variant='contained' color="buttoncolor" onClick={e => setSelectedYear(2020)}>2020</Button>
          <Button sx={{ margin: theme.spacing(0.5) }} variant='contained' color="buttoncolor" onClick={e => setSelectedYear(2021)}>2021</Button>
          <Button sx={{ margin: theme.spacing(0.5) }} variant='contained' color="buttoncolor" onClick={e => setSelectedYear(2022)}>2022</Button>
          <Button sx={{ margin: theme.spacing(0.5) }} variant='contained' color="buttoncolor" onClick={e => setSelectedYear(2023)}>2023</Button>
          <Button sx={{ margin: theme.spacing(0.5) }} variant='contained' color="buttoncolor" onClick={e => setSelectedYear(2024)}>2024</Button>
        </div>
        <div className="scroll-container" style={{ marginTop: theme.spacing(1), width: "100%", height: "100%", display: "flex", margin: "0 auto", overflow: "scroll" }}>
          <Marcdown config={{ source: markdown, class: "markdownStyle" }} />
        </div>
        <div style={{ margin: "0 auto", display: "flex", width: "90%" }}>
          <Imagegrid images={images} />
        </div>
      </div>
    </ThemeProvider >
  );
}

export default App;
